@import "../common/colors.scss";
// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

// @import '../fonts/AvenirLTStd-Black.otf';

body {
  height: 100%;
  font-family: "Avenir", sans-serif;

  #root {
    height: 100%;
  }

  .App {
    height: 100%;
  }
}

body {
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    caret-color: transparent;
  }

  .ant-select.ant-select-show-search {
    .ant-select-selection-item {
      padding-left: 10px;
    }
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 36px;
    padding: 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 5px;
  }

  .ant-select-multiple.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    min-height: 36px;

    .ant-select-selection-overflow {
      flex-wrap: nowrap;
    }
  }

  // .ant-select-single.ant-select-show-arrow.ant-select-show-search .ant-select-selector .ant-select-selection-placeholder {
  //     line-height: 42px;
  //     padding-left: 16px;
  // }

  .ant-picker {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 36px;
    padding: 7px 15px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 5px;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #e0e0e0;
    border-right-width: 1px;
  }

  .ant-picker-focused {
    border-color: transparent;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    outline: 0;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #022f46;
  }

  .ant-radio-checked::after {
    border: 1px solid #022f46;
  }

  .ant-radio-inner::after {
    background-color: #022f46;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-item-label>label {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 36px;
    padding: 7px 15px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 5px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    // padding: 10px;
    line-height: 20px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #e0e0e0;
    border-right-width: 1px !important;
  }

  .ant-input {
    min-height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 7px 15px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }

    &:-webkit-autofill {
      background-color: transparent !important;
    }
  }

  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: #ff4d4f;
    text-align: right;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: transparent;
    border: 1px solid #e1e1e1;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input:hover {
    border-color: transparent;
    border: 1px solid $midgray;
  }

  .ant-form-item textarea.ant-input {
    resize: none;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 5px 5px 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e1e1e1;
    border-right-width: 1px;
    z-index: 1;
  }

  .ant-input-affix-wrapper {
    min-height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 7px 15px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      border-color: $midgray;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    .ant-input {
      min-height: 20px;
      border: 0;
      width: 100%;
      padding: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-divider-horizontal {
    margin: 16px 0px 32px 0px;
    border-top: 1px solid #e7e7e7;
  }

  .ant-btn {
    background: #022f46;
    border-radius: 5px;
    min-height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $white;
    border: 0;

    &:hover,
    &:focus {
      color: $white;
      // background: #022F46;
    }

    &.ant-btn-primary {
      background: #022f46;
    }

    &.ant-btn-secondary {
      background: $offwhite;
      color: $darkgray;

      &:hover,
      &:focus {
        color: $darkgray;
        background: $offwhite;
      }
    }

    &.ant-btn-sm {
      font-size: 16px;
      min-height: 35px;
      display: inline-flex;
      align-items: center;
    }

    &.ant-btn-link {
      background: none;
      color: #022f46;
      text-decoration: underline;
      padding: 0;
      height: auto;
      width: auto;

      &:hover {
        background: none;
        color: #022f46;
        text-decoration: underline;
      }
    }

    .anticon {
      font-size: 18px;
      display: inline-flex;
    }
  }

  .ant-btn1 {
    background: #022f46;
    border-radius: 5px;
    min-height: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    border: 0;
    padding: 0 8px;

    &:hover,
    &:focus {
      color: $white;
      // background: #022F46;
    }

    &.ant-btn-primary {
      background: #022f46;
    }

    &.ant-btn-secondary {
      background: $offwhite;
      color: $darkgray;

      &:hover,
      &:focus {
        color: $darkgray;
        background: $offwhite;
      }
    }

    &.ant-btn-sm {
      font-size: 16px;
      min-height: 35px;
      display: inline-flex;
      align-items: center;
    }

    &.ant-btn-link {
      background: none;
      color: #022f46;
      text-decoration: underline;
      padding: 0;
      height: auto;
      width: auto;

      &:hover {
        background: none;
        color: #022f46;
        text-decoration: underline;
      }
    }

    .anticon {
      font-size: 18px;
      display: inline-flex;
    }
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .bottomButtons {
    .ant-space {
      display: flex;
      justify-content: space-between;
    }

    .bottomnote {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #44c4a1;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    .ant-btn {
      min-width: 150px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $darkblue;
    border-color: $midgray;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $midgray;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $midgray;
  }

  .ant-select-selection-item {
    overflow: visible;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e0e0e0;
    box-shadow: none;
  }

  .selectitem {
    display: inline-block;
    align-items: center;

    .flag {
      margin-right: 7px;
      margin-left: 5px;

      img {
        vertical-align: top;
        width: 34px;
      }
    }

    .selectname {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(52, 52, 52, 0.4);
    }
  }
}

.ant-btn {
  background: #022f46;
  border-radius: 5px;
  min-height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $white;
  border: 0;

  &:hover,
  &:focus {
    color: $white;
    background: #022f46;
  }

  &.ant-btn-primary {
    background: #022f46;
  }

  &.ant-btn-secondary {
    background: $offwhite;
    color: $darkgray;

    &:hover,
    &:focus {
      color: $darkgray;
      background: $offwhite;
    }
  }

  &.ant-btn-sm {
    font-size: 16px;
    min-height: 35px;
    display: inline-flex;
    align-items: center;
  }

  .anticon {
    font-size: 18px;
    display: inline-flex;
  }
}

.ant-tooltip-inner {
  background-color: rgba(2, 47, 70, 0.9);
  border-radius: 10px;
  padding: 10px 16px;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom,
      rgba(2, 47, 70, 0.9),
      rgba(2, 47, 70, 0.9));
}

.fieldlabel {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #343434;
  display: block;
}

.fieldnote {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #343434;
  opacity: 0.7;
  display: block;
  margin-bottom: 5px;
}

.APImodel {
  .ant-modal-content {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }

  .ant-modal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #022f46;
  }

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }

  .modelhead {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #022f46;
    margin-bottom: 10px;

    label {
      font-weight: 400;
      color: #343434;
    }

    .ant-col {
      padding-bottom: 5px;
    }
  }
}

.WPmodel {
  .ant-modal-content {
    background: url(../images/wpbg.png) no-repeat center #fff;
    background-size: cover;
    border-radius: 10px;
    border: 0;
  }

  .wpwrap {
    text-align: center;
    max-width: 380px;
    margin: 0 auto;

    .otp-input {
      justify-content: center;
      margin-bottom: 32px;
    }

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 20px;
      color: #343434;
      margin-bottom: 16px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
      opacity: 0.7;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #343434;
      margin-bottom: 16px;
      display: block;
    }

    .ant-btn {
      width: 100%;
    }
  }
}

.sendinquiry {
  .ant-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      rotate: (-40deg);
      margin-top: -5px;
      margin-left: 10px;
    }
  }
}

.table_grid {
  .certifycol {
    img {
      width: 24px;
    }
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #022f46;
  }

  .ant-table-column-sorters {
    justify-content: normal;

    .anticon {
      font-size: 12px;
    }
  }

  .ant-table-column-title {
    flex: none;
  }

  .tabledesc {
    display: flex;
  }

  .action {
    color: $darkgray;
    font-size: 20px;
  }

  .anticon {
    font-size: 20px;
  }

  .ant-table-thead>tr>th {
    background: #022f46;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-transform: capitalize;
  }

  .ant-table-tbody>tr>td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #343534;
    background: #f6fbfd;
  }

  .ant-table-tbody>tr:nth-child(even)>td {
    background: #dff1fa;
  }

  .ant-table-tbody>tr.ant-table-row:hover>td,
  .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #e9f8ff;
  }
}

.gridselect {
  .active {
    color: #022f46;
  }

  .Inactive {
    color: #0077d5;
  }

  .pending {
    color: red;
  }

  .Delete {
    color: #787878;
  }

  .resolved {
    color: #022f46;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    padding: 4px 8px 4px 8px;
    font-size: 13px;
    min-width: 120px;
  }
}

.gridselectticket {
  .active {
    color: #022f46;
  }

  .Inactive {
    color: #0077d5;
  }

  .pending {
    color: red;
  }

  .Delete {
    color: #787878;
  }

  .resolved {
    color: #022f46;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    padding: 4px 8px 4px 8px;
    font-size: 13px;
    min-width: 155px;
  }
}
.searchwrp {
  min-height: 36px;
  max-height: 36px;
  display: inline-block;
  vertical-align: top;

  .ant-btn {
    background: none;
    background-color: transparent;
    min-height: 28px;
  }

  .ant-input-group-addon {
    background: none;
    background-color: transparent;
  }

  .ant-input-wrapper.ant-input-group {
    border: 1px solid #cacaca;
    border-radius: 5px;
  }

  .ant-input-group .ant-input {
    border: 0;
    min-height: 35px;
    padding: 5px 15px;
  }

  ::placeholder {
    color: red;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #e2e2e2;
    min-height: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    box-shadow: none;
    min-width: 250px;
    vertical-align: top;
    border-radius: 0;
    padding: 5px 0;
    padding-left: 10px;

    .ant-input-suffix {
      font-size: 20px;
    }
  }
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.ant-pagination-item-active {
  border: 0 !important;
}

.ant-pagination-item a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #8e8e8e;
  background: #f3fbff;
}

.ant-pagination-options .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 33px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: #f3fbff !important;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
  background: #022f46;
  border: 1px solid #ebebeb;
  border-radius: 2px;
}

.ant-pagination-item:hover {
  border-color: #022f46 !important;
}

.ant-pagination-item:hover a {
  color: #022f46 !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #022f46 !important;
  border-color: #022f46 !important;
}

.ant-pagination .anticon {
  font-size: 14px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 20px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  font-size: 16px;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.contentpart {
  padding-top: 90px;
  min-height: 100%;
  background: $lightgray;
  background-image: url(../images/inr-right-top.png),
    url(../images/inr-left-bottom.png);
  background-position: right top, left bottom;
  background-repeat: no-repeat, no-repeat;
  height: 100%;

  .right_content_col {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    height: 100%;
    max-height: 100%;
    min-height: 100%;

    .pagename {
      // padding: 10px 53px;
      // padding: 10px 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // min-height: 64px;
      // flex-direction: column;
      background: #022f46;
      align-items: center;
      padding: 10px 23px;
      min-height: 54px;

      .ant-breadcrumb {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8) !important;
      }

      .ant-breadcrumb a {
        color: rgba(255, 255, 255, 80);
      }

      .ant-breadcrumb-separator {
        color: rgba(255, 255, 255, 0.8);
      }

      .ant-breadcrumb a:hover,
      .ant-breadcrumb li:last-child,
      .ant-breadcrumb li:last-child a {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .content_wraper {
      padding: 32px;
      padding-top: 0;
      overflow: auto;
      flex: auto;
      margin-top: 12px;

      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
      }

      &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.15);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
      }
    }
  }
}

.admindashboard {
  .countcardrow {
    .dcountwrap {
      background: #ffffff;
      // box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.08);
      padding: 16px;
      display: flex;
      margin-bottom: 24px;
      align-items: flex-start;
      height: calc(100% - 24px);
      // border: 1px solid #c5d9e3;
      border: 1px solid #d7dee0;

      .iconwrap {
        margin-right: 16px;
        min-width: 60px;
        font-size: 40px;
        color: #3aa4ab;
        display: flex;
        justify-content: center;

        svg {
          height: auto;
          width: 35px;
        }
      }

      .countcont {
        text-align: left;

        h3 {
          margin: 0;
          margin-bottom: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 29px;
          line-height: 26px;
          color: #010101;
          word-break: break-all;
        }

        p {
          margin: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #343434;
          opacity: 0.7;
        }
      }
    }
  }

  .graphtablewrap {
    .graphwrap {
      background: #ffffff;
      // border: 1px solid #c5d9e3;
      // box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.08);
      padding: 16px;
      margin-bottom: 24px;
      border: 1px solid #d7dee0;

      h3 {
        margin: 0;
        margin-bottom: 24px;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #343434;
      }
    }

    .piewrap {
      background: #ffffff;
      // box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.08);
      // border: 1px solid #c5d9e3;
      padding: 16px;
      margin-bottom: 24px;
      border: 1px solid #d7dee0;

      h3 {
        margin: 0;
        margin-bottom: 24px;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #343434;
      }
    }

    .dtablewrap {
      background: #ffffff;
      // border: 1px solid #c5d9e3;
      padding: 16px;
      margin-bottom: 24px;
      height: calc(100% - 24px);
      border: 1px solid #d7dee0;
    }

    h3 {
      margin: 0;
      margin-bottom: 24px;
      text-align: left;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: #343434;
    }
  }
}

.myaccount {
  background: #ffffff;
  padding: 22px;
  min-height: 100%;

  .myprofile {
    .prflrow {
      margin-bottom: 32px;
      flex-wrap: nowrap;
    }

    .heading {
      padding: 24px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 24px;

      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }

    .profileimg {
      max-width: 150px;

      .imgdiv {
        position: relative;
        width: 150px;
        height: 150px;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 5px;

        img {
          max-width: 100%;
        }

        .ant-btn {
          min-height: 30px;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #3aa4ab;
          border: 0;
          color: #fff;
        }

        .ant-upload.ant-upload-select-picture-card {
          width: 100%;
          height: 100%;
          margin-right: 0;
          margin-bottom: 0;
          background-color: transparent;
          border: 0;
        }

        .ant-upload-picture-card-wrapper {
          width: 30px;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 30px;
        }

        .ant-upload.ant-upload-select-picture-card>.ant-upload {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }

    .prflcont {
      padding-left: 32px;
      max-width: 640px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #343434;
      text-align: left;

      label {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        opacity: 0.5;
        display: block;
      }

      .ant-form-item {
        font-size: 16px;
      }

      a {
        color: #343434;
      }
    }
  }

  .changepassword {
    padding: 32px;
    background: #f3f3f3;
    border-radius: 5px;

    .heading {
      padding-bottom: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      text-align: left;

      h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }
  }
}

.adduser {
  background: #ffffff;
  padding: 22px;
  min-height: 100%;
}

.createticket {
  background: #ffffff;
  padding: 22px;
  min-height: 100%;

  .ticketheading {
    padding: 16px;
    background: #f3f3f3;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .imgdiv {
      padding: 16px;
      margin-right: 16px;

      img {
        max-width: 100%;
      }
    }

    .description {
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #022f46;
        margin: 0;
        margin-bottom: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        margin: 0;
      }
    }
  }
}

.manageuser {
  background: rgba(255, 255, 255, 1);
  padding: 22px;
  min-height: 100%;

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }
}

.adminsetting {
  background: #ffffff;
  padding: 22px;
  min-height: 100%;
  text-align: left;

  .subadminselect {
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      padding-right: 18px;
    }

    .ant-select {
      margin-top: 16px;
      width: "200px";
      padding: "10px";
    }
  }

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }

  .settingcont {
    padding: 32px;
    background: #f3f3f3;
    margin-top: 16px;

    .ant-form-item {
      margin-bottom: 16px;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #343434;
      height: auto;
    }

    .ant-switch-checked {
      background-color: #022f46;
    }
  }
}

.addfacilitytype {
  background: rgba(255, 255, 255, 1);
  padding: 22px;
  min-height: 100%;
  text-align: left;

  .plant-lab {
    padding: 16px;
    background: #f3f3f3;
    margin-bottom: 16px;

    .graybox {
      padding: 0;
      background: none;

      .download {
        margin-top: 10px;
        display: flex;

        .ant-space-item {
          width: auto;
        }

        .anticon.anticon-download {
          color: #fff;
          background: #022f46;
          border-radius: 5px;
          padding: 5px;
          margin: 0;
        }
      }
    }

    .ant-form-item textarea.ant-input {
      max-height: 70px;
    }

    .ant-form-item-label>label {
      height: auto;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    h3 {
      margin: 0;
      margin-bottom: 5px;
      font-weight: 600;
    }

    .ant-pagination {
      margin-bottom: 0;
    }

    .ant-space {
      width: 100%;

      .ant-space-item {
        width: 100%;
      }
    }

    .ant-table-cell {
      .ant-space {
        width: auto;

        .ant-space-item {
          width: auto;
        }
      }
    }

    .savebtn {
      .ant-space {
        width: auto;
      }
    }
  }

  .radiolbl {
    display: block;
    margin-bottom: 5px;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 36px;
    // height: auto;
  }

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }

  .uploadwrap {
    text-align: left;
    margin-bottom: 16px;
  }

  .ant-space-item {
    &:first-child {
      flex: auto;
    }
  }

  .ant-space.tabbutton {
    .ant-btn-link {
      min-height: inherit;
      background: none;
    }

    .ant-space-item {
      margin: 0px 16px 16px 0px;

      &:first-child {
        flex: none;
      }
    }
  }

  .uploadinside {
    .ant-space {
      width: auto !important;
    }

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #343434;

      a {
        color: #3aa4ab;
      }
    }

    .ant-upload-list {
      display: flex;
      margin-top: 16px;

      .ant-upload-list-text-container {
        margin: 0px 5px 5px 0px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;

        .ant-upload-list-item {
          height: auto;
          margin: 0;
          padding: 5px 10px;
        }

        .ant-btn {
          background: #fff;
        }

        .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
          color: #000;
          opacity: 0.5;
        }

        .ant-upload-list-item-error,
        .ant-upload-list-item-error .ant-upload-text-icon>.anticon,
        .ant-upload-list-item-error .ant-upload-list-item-name {
          color: #022f46;
        }
      }
    }

    .ant-upload-list-text .ant-upload-list-item-name {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
    }

    .ant-upload {
      display: flex;
      align-items: center;

      .ant-btn {
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(52, 52, 52, 0.4);
      }

      .condition {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #343434;
        opacity: 0.6;
      }
    }
  }

  .subradiowrap {
    margin-bottom: 10px;

    .ant-radio-wrapper {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
    }

    &.cdmo {
      margin: 24px 0px 0px 0px;
    }
  }

  .ant-form-item-label>label {
    white-space: normal;
  }

  .savebtn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .savebtnclassification {
    display: flex;
    align-items: flex-end;
    margin-top: 21px;
    // justify-content: flex-end;
  }

  .subttl2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #343434;
  }

  .photogallery {
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
      opacity: 0.6;
    }

    .picturewrap {
      padding: 10px;
      background-color: #f3f3f3;
      border: 0;
      border-radius: 5px;
      position: relative;
      margin-bottom: 16px;
      text-align: center;
      min-height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        max-height: 160px;
        max-width: 100%;
      }

      .btnwrp {
        bottom: 10px;
        right: 10px;
        top: auto;
        left: auto;
        transform: none;
        position: absolute;
      }
    }

    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 10px;
      background-color: #e9f5fb;
      border: 0;
      border-radius: 5px;
    }

    .anticon-eye {
      display: none;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-actions {
      bottom: 10px;
      right: 10px;
      top: auto;
      left: auto;
      transform: none;
    }

    .ant-btn.ant-btn-sm {
      min-height: 26px;
      justify-content: center;
    }

    .image-upload-grid {
      background-color: #f3f3f3;
      border: 0;
      border-radius: 5px;
      width: 100%;
      height: calc(100% - 16px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;

      .anticon {
        font-size: 40px;
        opacity: 0.2;
      }
    }

    .image-upload-grid-last {
      background-color: white;
      border: 0;
      border-radius: 5px;
      width: 100%;
      height: calc(100% - 16px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;

      .anticon {
        font-size: 40px;
        opacity: 0.2;
      }
    }

    .ant-upload.ant-upload-select-picture-card {
      background-color: #e9f5fb;
      border: 0;
      border-radius: 5px;

      .anticon {
        font-size: 40px;
        opacity: 0.2;
      }
    }
  }

  .graybox {
    padding: 16px;
    background: #f3f3f3;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 16px;

    .download {
      display: flex;

      .anticon.anticon-download {
        color: #fff;
        background: #022f46;
        border-radius: 5px;
        padding: 5px;
      }
    }

    .plusfield {
      font-size: 20px;
    }
  }

  .tabbutton {
    margin-bottom: 10px;
    margin-top: 24px;

    .ant-btn-link {
      color: #8097a3;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-decoration: none;

      &.success {
        color: #022f46;
        background: none;

        >div {
          background: #3aa4ab;

          .anticon {
            color: #fff;
          }
        }
      }

      >div {
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #d5d5d5;
        width: 32px;
        height: 32px;
        border-radius: 3px;
      }
    }
  }
}

.myorder {
  background: rgba(255, 255, 255, 1);
  padding: 22px;
  min-height: 100%;

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }
}

.mycompany {
  background: rgba(255, 255, 255, 1);
  padding: 22px;

  min-height: 100%;

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }

  .formtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    // color: #b6b6b6;
    // color: #7b7e7e;
    color: #858a8d;
    text-align: left;
  }

  .pluscol {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  .uploadwrap {
    padding: 16px;
    background: #f3f3f3;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 16px;

    .videourl {
      margin-top: 10px;

      .ant-space {
        width: 100%;

        .ant-space-item {
          &:first-child {
            flex: auto;
          }

          .ant-btn.ant-btn-primary {
            padding-bottom: 0;
            display: flex;
            align-items: center;
          }

          .ant-form-item {
            margin: 0;
          }
        }
      }
    }

    .downlod {
      margin-top: 10px;

      .anticon.anticon-download {
        color: #fff;
        background: #022f46;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }

  .uploadinside {
    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #343434;
    }

    .delet {
      margin-right: 10px;
    }

    .dwnload {
      padding: 0;
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      min-height: inherit;
      justify-content: center;

      .anticon {
        margin: 0;
      }
    }

    .ant-upload-list {
      display: flex;

      .ant-upload-list-text-container {
        margin: 0px 5px 5px 0px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;

        .ant-upload-list-item {
          height: auto;
          margin: 0;
          padding: 5px 10px;
        }

        .ant-btn {
          background: #fff;
        }

        .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
          color: #000;
          opacity: 0.5;
        }

        .ant-upload-list-item-error,
        .ant-upload-list-item-error .ant-upload-text-icon>.anticon,
        .ant-upload-list-item-error .ant-upload-list-item-name {
          color: #022f46;
        }
      }
    }

    .ant-upload-select {
      margin-bottom: 0px;
    }

    .ant-upload-list-text .ant-upload-list-item-name {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
    }

    .ant-upload {
      display: flex;
      align-items: center;

      .ant-btn {
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(52, 52, 52, 0.4);
        //  min-height: 36px;
      }

      .condition {
        margin-top: 20px;
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #343434;
        opacity: 0.6;
      }
    }
  }
}

.viewfacility {
  background: #ffffff;
  padding: 22px;
  min-height: 100%;

  .ant-input:read-only {
    font-size: 16px !important;
    color: #343434;
    background: #f5f5f5;
  }

  .ant-form-item-control-input {
    min-height: inherit;
  }

  .ant-input-affix-wrapper-readonly {
    font-size: 16px !important;
    color: #343434;
    background: #f5f5f5;
  }

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 24px;

    .anticon-download {
      margin: 0;
    }

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }

  .viewform {
    text-align: left;

    .ant-col {
      padding-bottom: 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
    }

    label {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #343434;
      opacity: 0.7;
    }
  }

  .widgetgroup {
    margin-bottom: 8px;

    .ant-col {
      padding-bottom: 24px;
    }

    .widgetwrp {
      min-height: 100px;
      display: flex;
      min-height: 100%;
    }

    .widgrtimg {
      background: #067981;
      border-radius: 5px 0px 0px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100px;
      padding: 16px;

      img {
        max-width: 100%;
        min-width: 24px;
      }
    }

    .widgetcont {
      background: #022f46;
      border-radius: 0px 5px 5px 0px;
      padding: 16px;
      display: flex;
      align-items: center;
      text-align: left;
      flex: auto;

      .inwidget {
        h3 {
          margin: 0;
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 38px;
          line-height: 30px;
          color: #ffffff;
        }

        h6 {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
          opacity: 0.8;
        }
      }
    }
  }

  .sectionpresentation {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: #022f46;
    text-align: left;
    margin-top: 16px;
    font-weight: 600;
  }

  .sectionheading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: #022f46;
    margin-bottom: 10px;
    text-align: left;
  }

  .regulatory {
    display: flex;
    margin-bottom: 32px;

    .ant-space {
      flex-wrap: wrap;
    }
  }

  .presentation {
    margin-bottom: 16px;

    &.prsentdownloadwrp {
      background: #f3f3f3;
      border-radius: 5px;
      padding: 16px;
    }

    .radio-group-down {
      margin-bottom: 20px;
    }

    .videourlfacility {
      margin-top: 10px;

      .ant-space {
        width: 100%;

        .ant-space-item {
          &:first-child {
            flex: auto;
          }

          .ant-btn.ant-btn-primary {
            padding-bottom: 0;
            display: flex;
            align-items: center;
          }

          .ant-form-item {
            margin: 0;
          }
        }
      }
    }

    .downlod {
      display: flex;
      align-items: center;

      .anticon.anticon-download {
        color: #fff;
        background: #022f46;
        border-radius: 5px;
        padding: 5px;
      }

      .ant-btn.ant-btn-link {
        display: flex;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        min-height: 36px;
        padding: 10px;
        align-items: center;
        text-decoration: none;
      }
    }

    .downlodView {
      display: flex;
      align-items: center;
      margin-left: 10px;
      gap: 10px;

      .anticon.anticon-download {
        color: #fff;
        background: #022f46;
        border-radius: 5px;
        padding: 5px;
      }

      .ant-btn.ant-btn-link {
        display: flex;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        min-height: 36px;
        padding: 10px;
        align-items: center;
        text-decoration: none;
      }
    }
  }

  .boxpresentation {
    // background: #fff;
    // border-radius: 5px;
    // padding: 16px;
    // display: flex;

    &.formv {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #022f46;
      word-wrap: break-word;
      display: block;
      margin-bottom: 16px;

      .presentation {
        padding: 0 !important;
        margin-top: 3px;
      }

      .downlod {
        display: inline-flex !important;
        padding: 5px 10px;
      }

      label {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        opacity: 0.5;
        display: block;
      }
    }
  }

  .menufect {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #343434;
    margin-bottom: 32px;

    img {
      margin-right: 10px;
      max-width: 40px;
    }
  }

  .suplyingroup {
    // margin-bottom: 32px;
    // background: #F9FCFE;
    padding: 16px 0;
    // padding: 16px 16px 0px 16px;

    .ant-col {
      padding-bottom: 16px;
    }

    .suplywrapwrp {
      min-height: 100px;
      display: flex;
      min-height: 100%;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      background: #fff;
    }

    .flagimg {
      background: #d7dee0;
      // background: #E0EDF3;
      border-radius: 5px 0px 0px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 48px;
      padding: 5px;

      img {
        max-width: 100%;
        min-width: 24px;
        mix-blend-mode: multiply;
      }
    }

    .flagname {
      padding: 16px;
      display: flex;
      align-items: center;
      text-align: left;
      flex: auto;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
    }
  }

  .subradiowrap {
    display: flex;
    margin-bottom: 10px;

    .ant-radio-wrapper {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
    }
  }

  .facitypeview {
    // background: #F9FCFE;
    // padding: 16px;
    padding-bottom: 1px;
    padding: 16px 0 1px;

    .viewform .ant-col {
      padding-bottom: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #022f46;
      word-wrap: break-word;
    }

    .subdetail {
      background: #f3f3f3;
      padding: 16px;
      margin-top: 16px;
      border-radius: 5px;

      .photoview {
        img {
          max-width: 100%;
          margin-bottom: 16px;
        }
      }

      .viewform {
        label {
          display: inline-block;
          margin: 10px 0px 5px 0px;
          height: auto;
        }
      }

      .sectionheading {
        font-size: 17px;
        // padding: 8px;
        padding: 8px 0px 0px 0px;
      }

      .downlod {
        display: flex !important;
        padding: 0;

        .anticon.anticon-download {
          color: #fff;
          background: #022f46;
          border-radius: 5px;
          padding: 5px;
        }

        .ant-btn.ant-btn-link {
          display: flex;
          background: #ffffff;
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #343434;
          min-height: 36px;
          padding: 10px;
          align-items: center;
          text-decoration: none;
        }
      }
    }
  }
}

.addfacility {
  background: #ffffff;
  padding: 32px;
  min-height: 100%;

  .heading {
    padding: 24px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }

  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 65px;
    padding: 3.5px 9px;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #e4e4e4;
    border-color: #e4e4e4;
  }

  .ant-steps-item-icon {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #878787;
  }

  .ant-steps-item-tail::after {
    height: 3px;
    background: #d9d9d9;
  }

  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #3aa4ab;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #3aa4ab;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: #3aa4ab;
    border-color: #3aa4ab;
  }

  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #fff;
  }

  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #3aa4ab;
  }

  .ant-steps-item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #a2a2a2;
  }

  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #022f46;
  }

  .ant-steps {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.concierge {
  background: #ffffff;
  padding: 22px;
  min-height: 100%;
  text-align: left;

  .normaltxt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #343434;
    opacity: 0.8;
    margin-bottom: 5px;
  }

  .sectionheading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #022f46;
    margin-bottom: 16px;
    text-align: left;
  }

  .conciergerow {
    margin-bottom: 16px;

    .ant-col {
      padding-bottom: 20px;
    }

    .conciergebox {
      position: relative;
      height: 100%;

      .conciergeshadow {
        width: 80%;
        height: 50%;
        bottom: 0;
        left: 10%;
        background: #fff;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        position: absolute;
      }

      .planbox {
        background: #ffffff;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        position: relative;
        z-index: 9;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 100%;

        .plantitle {
          padding: 16px;
          background: #022f46;
          border-radius: 5px 5px 0px 0px;
          position: relative;
          text-align: center;

          .ant-space {
            margin-top: 10px;
            text-wrap: nowrap;
            flex-wrap: wrap;
            gap: 10px !important;
            justify-content: center;

            .ant-space-item {
              padding: 0px 5px;
            }
          }

          .offerbox {
            text-align: center;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.5);
            border-top: 2px solid #3aa4ab;
            border-bottom: 2px solid #3aa4ab;
            padding: 5px 0px;

            h4 {
              font-size: 18px;
              margin: 0;
              color: #4bbcc4;
            }
          }

          &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 29px;
            height: 29px;
            content: "";
            background: url(../images/titleBLF.png) no-repeat left top;
          }

          &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 29px;
            height: 29px;
            content: "";
            background: url(../images/titleBRT.png) no-repeat left top;
          }

          h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: #ffffff;
            margin: 0;
            margin-bottom: 5px;
          }

          h6 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            opacity: 0.5;
            margin: 0;
          }
        }

        .plancont {
          padding: 15px 30px;
          position: relative;
          text-align: center;
          flex: auto;

          &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 29px;
            height: 29px;
            content: "";
            background: url(../images/contTLF.png) no-repeat left top;
          }

          &::after {
            position: absolute;
            right: 0;
            top: 0;
            width: 29px;
            height: 29px;
            content: "";
            background: url(../images/contTRT.png) no-repeat left top;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #343434;
            opacity: 0.8;
          }

          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #022f46;
            opacity: 0.8;
          }

          ul {
            padding: 0;
            list-style-type: none;
            text-align: left;
            max-width: 370px;
            margin: 0 auto;

            li {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #343434;
              opacity: 0.8;
              display: flex;
              align-items: flex-start;
              margin-bottom: 10px;

              img {
                max-width: 14px;
                margin-right: 8px;
                margin-top: 3px;
              }
            }
          }
        }

        .btnwrp {
          padding: 0px 15px 15px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ant-btn.ant-btn-link {
          display: flex;
          background: #ffffff;
          border: 1px solid #022f46;
          border-radius: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #022f46;
          min-height: 36px;
          padding: 10px;
          align-items: center;
          text-decoration: none;
        }
      }
    }
  }
}

.businesslounge {
  background: rgba(255, 255, 255, 1);
  min-height: 100%;
  text-align: left;
  padding: 22px;

  .subradiowrap {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    padding: 15px 15px;

    .ant-radio-wrapper {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
    }

    &.cdmo {
      margin: 24px 0px 0px 0px;
    }
  }

  .whatsupwrp {
    background: url(../images/whatsupbg.png) no-repeat center;
    background-size: cover;
    // padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-top: 20px;
    background: #d7dee0;

    .number {
      color: #222;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      .anticon {
        margin-left: 10px;
      }
    }
  }

  .loungetype {
    &.shortlistcompany {
      .heading {
        padding: 7px 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $black;

        h3 {
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 0.01em;
        }
      }
    }

    .paginatewrp {
      display: flex;
      justify-content: flex-end;
    }

    .centermsgnotfound {
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }

    .searchwrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px;

      .ant-input-group-wrapper {
        max-width: 780px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        // box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .ant-input-wrapper {
          display: flex;
          align-items: center;
          padding-right: 80px;
        }

        .ant-input-affix-wrapper {
          border: 0;
        }
      }
    }

    .ant-collapse {
      border: 0;
      background: #eeeeee;
      margin-bottom: 16px;
    }

    .ant-collapse>.ant-collapse-item {
      border: 0;
    }

    .ant-collapse-content {
      border: 0;
      background: #f3f3f3;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      flex-direction: row-reverse;
    }

    .filterwrp {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .ant-select-selection-placeholder {
        color: #777777;
      }

      ::placeholder {
        color: #777777;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
        padding: 4px 11px;
      }

      .ant-select-single.ant-select-show-arrow.ant-select-show-search .ant-select-selector .ant-select-selection-placeholder {
        line-height: 20px;
      }

      .ant-select-selection-item .flag,
      .ant-select-item-option-content .flag {
        margin-left: 0;
      }

      .ant-input {
        min-height: 32px;
        padding: 4px 15px;
        width: 250px;
      }

      .ant-divider-vertical {
        height: 30px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0 16px;
      }

      .sorter {
        margin: 5px 0px;

        .ant-form-item {
          margin: 0;
        }
      }

      .filtr {
        margin: 5px 0px;

        .ant-space {
          flex-wrap: wrap;
        }

        .ant-form-item {
          margin: 0;
        }

        .ant-select.ant-select-in-form-item {
          &.country {
            width: 220px;

            .ant-select-selection-item {
              padding-left: 0;
            }
          }

          &.regulatory {
            width: 185px;
          }

          &.qty {
            width: 225px;
          }

          &.capacity {
            width: 265px;
          }
        }

        .ant-input {
          &.capacity {
            width: 210px;
          }
        }
      }
    }

    .viewiconwrap {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;

      .card {
        border-radius: 5px 0px 0px 5px;
      }

      .grid {
        border-radius: 0px 5px 5px 0px;
      }

      .ant-btn {
        background: #f3f3f3;
        min-height: 36px;
        padding: 4px 10px;

        .anticon {
          color: #343434;
        }

        &.active {
          background: #022f46;

          .anticon {
            color: #fff;
          }
        }
      }
    }

    .loungerow {
      .ant-col {
        padding-bottom: 32px;
      }

      .loungebox {
        position: relative;
        height: 100%;

        .loungeshadow {
          width: 80%;
          height: 50%;
          bottom: 0;
          left: 10%;
          background: #fff;
          box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
          position: absolute;
        }

        .loungecard {
          background: #ffffff;
          border: 1px solid #022f46;
          border-radius: 5px;
          position: relative;
          z-index: 9;
          display: flex;
          justify-content: center;
          flex-direction: column;
          min-height: 100%;

          .loungetitle {
            padding: 16px;
            background: #022f46;
            border-radius: 5px 5px 0px 0px;
            position: relative;

            .countrywrp {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0px;

              .ant-btn {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                padding: 0;
                min-height: inherit;

                &:focus {
                  box-shadow: none;
                  border-color: transparent;
                  background: none;
                }
              }

              .countrytag {
                display: flex;
                align-items: center;
                color: #fff;

                .imgwrp {
                  margin-right: 7px;
                  border-radius: 3px;
                  padding: 0;
                  background-color: transparent;
                  display: flex;
                  align-items: center;
                  color: #fff;
                  max-width: 40px;
                  height: 15px;

                  img {
                    width: 100%;
                  }
                }
              }
            }

            h3 {
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #ffffff;
              margin: 0;
              margin-bottom: 0px;
            }

            h6 {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #ffffff;
              opacity: 0.5;
              margin: 0;
            }
          }

          .loungecont {
            padding: 0px 16px 6px 16px;
            position: relative;
            flex: auto;

            ul {
              padding: 0;
              margin: 0;
              list-style-type: none;
              text-align: left;

              li {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 5px 0px;

                &:last-child {
                  border: 0;
                }

                label {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #343434;
                  opacity: 0.6;
                  display: block;
                }

                .countrywrper {
                  display: flex;
                  flex-wrap: wrap;
                }

                .countrynm {
                  margin-right: 5px;
                  margin-bottom: 5px;
                  align-items: center;

                  img {
                    width: 32px;
                    height: auto;
                  }
                }

                .cardrowhead {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #343434;
                  opacity: 0.6;
                  margin: 0 !important;

                  .ant-col {
                    padding: 7px 5px 7px 0px !important;
                  }
                }

                .cardrowvalue {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #343434;
                  margin: 0 !important;

                  .ant-col {
                    padding: 7px 5px 7px 0px !important;
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                  }
                }

                .morelink {
                  display: flex;
                  justify-content: flex-end;

                  a {
                    color: #022f46;
                    font-size: 14px;
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .regulatory {
            margin-bottom: 5px;

            h2 {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: #022f46;
              text-align: center;
              padding: 5px 10px;
              background: #f8f8f8;
            }

            .ant-space-item {
              width: auto;
              margin: 3px;

              img {
                max-width: 32px;
                height: auto;
              }
            }

            .ant-space {
              width: 100%;
              padding: 0px 10px;
              flex-wrap: wrap;
            }
          }

          .btnwrp {
            padding: 0px 16px 16px 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-space {
              width: 100%;
            }

            .ant-btn {
              min-height: 36px;
              font-size: 16px;
              width: 100%;
            }

            .ant-space-item {
              width: 50%;
            }
          }
        }
      }
    }

    .businesslounge-btn {
      font-size: 15px;
      min-height: 26px;
    }
  }
}

.mt-30 {
  margin-top: 30px;
}

.mb-5 {
  margin-bottom: 5px;
}



.table_grid .certifycol img {
  width: 35px;
}

.react-confirm-alert-button-group>button {
  background: #022f46;
}

.tourimg {
  max-width: 100%;

  img {
    width: 100%;
  }
}

.tourtitle {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 16px;
}

.autoComplete .ant-select-selection-placeholder {
  line-height: 35px !important;
  padding-left: 15px !important;
  color: #777777 !important;
}

.autoComplete {
  width: 500px !important;
}

.autoComplete input {
  caret-color: black !important;
}

@media only screen and (max-width: 481px) {
  .contentpart {
    background-size: 36%;
  }

  .businesslounge .loungetype .searchwrap {
    display: block;
  }

  .businesslounge .loungetype .searchwrap .ant-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .autoComplete {
    width: 100% !important;
  }
}

.ant-table-content {
  border: 1px solid #d7dee0;
}

//   ::placeholder{color:red;}

// .ant-select-selection-placeholder {
//     color: red;
//   }
//   .ant-picker-input input::placeholder {
//     color: blue;
//   }

.ant-table-ping-right:not(.ant-table-has-fix-right)>.ant-table-container::after,
.ant-table-ping-right:not(.ant-table-has-fix-right)>.ant-table-container::before {
  box-shadow: none !important;
}

.react-tel-input .country-list .country {
  text-align: left;
}

.react-tel-input .form-control {
  height: 35px !important;
  width: 100% !important;
  padding-left: 58px !important;
}

.react-tel-input .selected-flag {
  width: 48px !important;
  padding: 0 0 0 12px !important;
}

.select_placeholder {
  padding: 6px;
  padding-left: 15px;
}

.table_grid .ant-table-tbody>tr>td {
  background: #f3f3f3;
}

.table_grid .ant-table-tbody>tr:nth-child(2n)>td {
  background: #fff;
}

.table_grid .ant-table-tbody>tr.ant-table-row:hover>td,
.table_grid .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #d7dee0;
}

.anticon-delete svg path {
  fill: red;
}

.iconwrap svg path {
  fill: #022f46;
  stroke: #022f46;
}

::placeholder {
  color: #aeaeae;
}

:-ms-input-placeholder {
  color: #aeaeae;
}

::-ms-input-placeholder {
  color: #aeaeae;
}

.searchwrp .anticon svg path {
  fill: #6d6d6d;
}

// a {color: #343534 !important; }
// a:active, a:hover {color: #022f46  !important;}

.link-color {
  color: #022f46 !important;
  display: inline-flex;

  .anticon {
    margin-right: 5px;
  }

  &:active,
  &:hover {
    color: #022f46 !important;
  }
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border-color: #022f46 !important;
}

.subadminselect .ant-select-selection-placeholder {
  line-height: 46px !important;
  padding-left: 10px !important;
}

.subadminselect.small .ant-select-selection-placeholder {
  line-height: 35px !important;
  padding-left: 10px !important;
}

.viewmargin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.headingpadding {
  padding-left: 7.5px;
  padding-right: 7.5px;
  width: 100%;
  padding-top: 7.5px;
  padding-bottom: 7.5px !important;
}

.anticon-download {
  margin-left: 10px;
}

.ant-row.viewform.viewpdf .ant-col {
  font-weight: 500;
}

.viewdetailform {
  align-items: flex-start;
  background: #fff;
  border: 1px solid #d7dee0;
  display: flex;
  margin-bottom: 24px;
  padding: 16px;
}

.remarkscroll {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.remarkscroll .ant-form-item textarea.ant-input {
  resize: none;
  width: 900px;
  border: none;
  margin-bottom: 0 !important;
  padding: 0 15px;
  height: 60px;
}

.remarkscroll .ant-form-item {
  margin-bottom: 0 !important;
}

.viewfacility .presentationwithoutbottom {
  margin-bottom: 0px;
}

.watersystem {}

.utilitywrp {
  .graybox .ant-form-item-has-success {
    margin-bottom: 0 !important;
  }

  .antspace {
    margin-top: 5px;
  }

  // .remarktextarea{
  //   max-height: 36px;
  // }
  .ant-form-item textarea.ant-input {
    resize: auto;
  }

  .resizebtn {
    margin-top: 32px;
  }
}

.watersystem .ant-space {
  display: inline-flex;
  width: 100% !important;
}

@media (min-width: 992px) {
  .watersyste .ant-col-lg-12 {
    display: block;
    max-width: 80% !important;
  }
}

@media (min-width: 576px) {
  .watersyste .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 80% !important;
  }
}

.watersyste .ant-space-align-center {
  align-items: center;
  width: 70%;
}

.otherutility .ant-space-align-center {
  align-items: center;
  width: 100%;
}

.btnFormulationDescription {
  float: right;
  margin-right: 20px;
}

.spaceHorizontal {
  .ant-col.ant-form-item-label {
    padding-bottom: 0px !important;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.horizontal-col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: stretch;

  .ant-form-item {
    width: 48%;
  }
}

tr.ant-table-measure-row[aria-hidden="true"] {
  display: none !important;
}

.presentationdwl {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 18px;
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab:hover {
  color: #022f46 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #022f46 !important;
}

.ant-tabs-ink-bar {
  background: #022f46 !important;
}

// .HeaderSidebarSec{border: 1px solid red;}
.WhatsAppIcon {
  cursor: pointer;
}

.ant-table-thead>tr>th {
  padding: 10px 16px !important;
}

.ant-table-tbody>tr>td {
  padding: 0px 16px !important;
}

.certifycol .ant-space-item {}

.certifycol .countrynm img {
  height: auto;
}

.table_grid .ant-table-tbody>tr>td {
  height: 42px;
}

.WPmodel .whatsupwrp .ant-space {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.WPWhatsppno {
  font-size: 25px;
  color: #022f46;
  font-weight: bold;
  display: flex;
  flex-direction: column;

  b {
    font-weight: 500;
  }

  span {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;

    a {
      color: #1890ff;
    }
  }
}

.WPmodel .ant-space-item {
  text-align: center;
}

.WPmodel .ant-btn.ant-btn-sm {
  font-size: 16px;
  min-height: 35px;
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
  padding: 0 20px;
}

.btn-style {
  flex: "none";
  padding: "8px";
  border: none;
  background: none;
  color: "#022f46";
}

.custom-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}

.notification-container {
  position: relative;
}

.notification-count {
  border-radius: 50%;
  color: #f7f0f0;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 6px;
  right: 0;
  top: 0;
  background: #022f46;
  margin-right: 4px;
}

.pricetitle {
  color: #022f46;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #f5f5f5;
  padding: 7px 16px;
  background: #eeeeee;
}

.pricecontWrp {
  padding: 16px;
  margin-bottom: 16px;
  background: #f3f3f3;

  .ql-editor {
    background: #fff !important;
  }
}

.priceDescription {
  margin-bottom: 16px;
}

.expirenote {
  padding: 10px 16px;
  display: flex;
  background-color: rgb(255 239 239);
  justify-content: space-between;
  margin: 16px 0px;
  border-radius: 5px;
  border: 1px solid #ffe1e1;

  p {
    font-size: 16px;
    margin: 5px 0px;
    font-weight: 600;
  }
}

.conciergeService {
  background: none !important;

  .sectionheading {
    color: #fff;
  }
}

.conciergeServiceTitle {
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 20px;
  border-radius: 8px;
}

@media only screen and (max-width: 1367px) {
  .contentpart {
    background-size: 20%;
  }
}

@media only screen and (max-width: 991px) {
  .contentpart .right_content_col .pagename {
    padding: 10px 16px;
    font-size: 18px;
  }

  .contentpart .right_content_col .content_wraper {
    padding: 16px;
    padding-top: 0;
  }

  .myaccount,
  .manageuser,
  .myorder,
  .mycompany,
  .addfacilitytype {
    padding: 16px;
  }

  .adduser,
  .createticket,
  .viewfacility,
  .concierge,
  .adminsetting {
    padding: 16px;
  }

  .adminsetting .settingcont {
    padding: 16px;
  }

  .adminsetting .heading {
    padding: 0px 0px 16px 0px;
  }

  .viewfacility .suplyingroup {
    // padding: 8px 0px 0px 0px;
    padding: 16px 0;
  }

  .viewfacility .facitypeview .subdetail {
    padding: 10px;
    margin-bottom: 16px;
  }

  .myaccount .changepassword {
    padding: 16px;
  }

  .addfacilitytype .tabbutton {
    margin-bottom: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .addfacilitytype .subradiowrap .ant-radio-wrapper {
    font-size: 14px;
  }

  .concierge .conciergerow {
    margin-bottom: 16px;
  }

  .viewfacility .boxpresentation {
    display: block;
  }

  .contentpart .right_content_col .pagename {
    display: block;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .conciergeServiceTitle {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .contentpart {
    background-size: 30%;
  }

  .manageuser .heading h3 {
    margin-bottom: 10px;
  }

  .myaccount .myprofile .profileimg .imgdiv {
    width: 100px;
    height: 100px;
  }

  .myaccount .myprofile .prflcont {
    padding-left: 16px;
  }

  body .ant-form-item {
    margin-bottom: 16px;
  }

  .addfacilitytype .ant-space.tabbutton .ant-space-item {
    margin: 0px 10px 10px 0px;
  }

  .addfacilitytype .tabbutton .anticon {
    font-size: 14px;
  }

  .addfacilitytype .tabbutton .ant-btn-link>div {
    width: 24px;
    height: 24px;
  }

  .addfacilitytype .tabbutton .ant-btn-link {
    font-size: 14px;
  }

  .addfacilitytype .heading h3 {
    font-size: 16px;
  }

  body .ant-btn.ant-btn-sm {
    font-size: 14px;
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 10px 10px;
  }

  .viewfacility .heading h3 {
    margin-bottom: 10px;
  }

  .concierge .conciergerow .conciergebox .planbox .plantitle h3 {
    font-size: 18px;
    line-height: 20px;
  }

  .concierge .conciergerow .conciergebox .planbox .plantitle h6 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .mt-30 {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.mt-1 {
  margin-top: 16px !important;
}