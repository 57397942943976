@import "../common/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Work Sans", sans-serif;

  #root {
    height: 100%;
  }

  .App {
    height: 100%;
  }
}

.text-center {
  text-align: center;
}

body {
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 35px;
    padding: 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 5px;
  }

  .ant-picker {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 36px;
    padding: 12px 15px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 5px;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #e0e0e0;
    border-right-width: 1px;
  }

  .ant-picker-focused {
    border-color: transparent;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    outline: 0;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #022f46;
  }

  .ant-radio-checked::after {
    border: 1px solid #022f46;
  }

  .ant-radio-inner::after {
    background-color: #022f46;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-item-label > label {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    // color: #343434;
    color: #343434c7;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 35px;
    padding: 7px 15px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border-radius: 5px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    // padding-left: 10px;
    // line-height: 20px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #e0e0e0;
    border-right-width: 1px !important;
  }

  .ant-input {
    min-height: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 4px 15px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }

    &:-webkit-autofill {
      background-color: transparent !important;
    }
  }

  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: #ff4d4f;
    text-align: right;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: transparent;
    border: 1px solid #e1e1e1;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input:hover {
    border-color: transparent;
    border: 1px solid $midgray;
  }

  .ant-form-item textarea.ant-input {
    resize: none;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 5px 5px 0;
  }

  .ant-input-affix-wrapper {
    min-height: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 4px 15px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      border-color: $midgray;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    .ant-input {
      min-height: 20px;
      border: 0;
      width: 100%;
      padding: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-divider-horizontal {
    margin: 16px 0px 32px 0px;
    border-top: 1px solid #e7e7e7;
  }

  .ant-btn {
    background: #022f46;
    border-radius: 5px;
    min-height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $white;
    border: 0;

    &:hover,
    &:focus {
      color: $white;
      // background: #022F46;
    }

    &.ant-btn-primary {
      background: #022f46;
      color: #ffffff;
    }

    &.ant-btn-secondary {
      background: $offwhite;
      color: $darkgray;

      &:hover,
      &:focus {
        color: $darkgray;
        background: $offwhite;
      }
    }

    &.ant-btn-sm {
      font-size: 16px;
      min-height: 35px;
      display: inline-flex;
      align-items: center;
    }

    .anticon {
      font-size: 18px;
      display: inline-flex;
    }
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .bottomButtons {
    text-align: right;

    .ant-space {
      display: flex;
      justify-content: space-between;
    }

    .bottomnote {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #44c4a1;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    .ant-btn {
      min-width: 150px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $darkblue;
    border-color: $midgray;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $midgray;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $midgray;
  }

  .ant-select-selection-item {
    overflow: visible;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid #e0e0e0;
    box-shadow: none;
  }

  .ant-select-multiple .ant-select-selection-item {
    // height: auto;
    line-height: 22px;
    padding-left: 4px !important;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-select-multiple .ant-select-selection-item-content {
    display: flex;
    align-items: center;
  }

  .ant-select-multiple .ant-select-selection-item .flag,
  .ant-select-multiple .ant-select-item-option-content .flag {
    margin-right: 5px;
    width: 36px;
    height: 100%;
    margin-left: 0;
  }

  .ant-select-selection-overflow {
    padding-left: 5px;
    padding-right: 25px;
  }

  .ant-select-clear {
    right: 27px;
  }

  .ant-select-selection-item,
  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    .selectitem {
      width: auto !important;
    }

    .flag {
      margin-right: 5px;
      width: 36px;
      height: 100%;
      margin-left: 10px;

      img {
        vertical-align: top;
        width: 32px;
      }
    }

    .selectname {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(52, 52, 52, 0.4);
    }
  }
}

.container {
  padding: 0px 15px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

.authPage {
  min-height: 100%;
  background: #022f46;
  background-image: url(../images/right-top.png), url(../images/left-bottom.png);
  background-position: right top, left bottom;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
  }

  .logorow {
    padding: 32px;
    text-align: left;

    img {
      max-width: 230px;
    }

    &.headerlogin {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-form-item {
        margin: 0;
      }

      .loginhead {
        display: flex;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 5px;

        label {
          padding: 12px 24px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          opacity: 0.7;
        }

        .ant-btn.ant-btn-primary {
          background: #fff;
          color: #022f46;
        }
      }
    }
  }

  .reg_wraper {
    width: 100%;
    max-width: 1000px;
    background: #fff;
    padding: 32px;
    margin: 0 auto;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);

    &.companydetail {
      max-width: 1600px;

      .customcol {
        .addwrp {
          display: flex;
          justify-content: flex-start;
          padding-top: 30px;
        }

        .anticon {
          font-size: 20px;
        }
      }

      .formtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #b6b6b6;
        text-align: left;
      }

      .pluscol {
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }

      .uploadwrap {
        padding: 16px;
        background: #f3f3f3;
        border-radius: 5px;
        text-align: left;
        margin-bottom: 16px;
      }

      .uploadinside {
        margin-top: 7px;

        h5 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #343434;
        }

        .ant-upload-list {
          display: flex;

          .ant-upload-list-text-container {
            margin: 0px 5px 5px 0px;
            background: #ffffff;
            border: 1px solid #e1e1e1;
            border-radius: 5px;

            .ant-upload-list-item {
              height: auto;
              margin: 0;
              padding: 5px 10px;
            }

            .ant-btn {
              background: #fff;
            }

            .ant-upload-list-item-error
              .ant-upload-list-item-card-actions
              .anticon {
              color: #000;
              opacity: 0.5;
            }

            .ant-upload-list-item-error,
            .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
            .ant-upload-list-item-error .ant-upload-list-item-name {
              color: #022f46;
            }
          }
        }

        .ant-upload-select {
          margin-bottom: 16px;
        }

        .ant-upload-list-text .ant-upload-list-item-name {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #343434;
        }

        .ant-upload {
          display: flex;
          align-items: center;

          .ant-btn {
            background: #ffffff;
            border: 1px solid #e1e1e1;
            border-radius: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(52, 52, 52, 0.4);
            min-height: 36px;
          }

          .condition {
            margin-left: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #343434;
            opacity: 0.6;
          }
        }
      }
    }

    &.acverfiy {
      padding: 0 32px 32px 32px;
      min-width: 500px;
      max-width: 500px;
    }

    .checkwrap {
      margin-bottom: 5px;
      margin-top: 0px;

      .ant-checkbox-wrapper {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        opacity: 0.8;

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #022f46;
        }
      }
    }

    .pagename {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #343434;
      position: relative;
      max-width: 530px;
      margin: 0 auto;
      margin-bottom: 0px;
    }

    .regnote {
      background-color: rgba(58, 164, 171, 0.2);
      padding: 10px;
      border-radius: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #343434;
      margin-bottom: 32px;
    }

    p {
      margin: 0;
      padding: 0;
      // margin-bottom: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #5c5c5c;
    }
  }

  .terms_wraper {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    max-width: 1300px;
  }

  .login_wraper {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    max-width: 900px;

    padding: 16px 0px;

    &.change_pass {
      max-width: 488px;
      padding: 32px;
      background: #fff;
      margin: 16px 0px;

      .bottomButtons .ant-btn {
        min-width: 100px;
      }

      .pagename {
        color: #343434;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 28px;
        position: relative;
        margin-bottom: 32px;
      }

      .bottomButtons .ant-space {
        justify-content: center;
      }
    }

    &.passwordsuccess {
      max-width: 488px;
      padding: 32px;
      background: #fff;
      margin: 16px 0px;

      .imageverify {
        padding: 32px 0px;
      }

      .bottomButtons .ant-btn {
        min-width: 100px;
      }

      p {
        margin-top: 16px;
      }

      .pagename {
        color: #343434;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        position: relative;
      }

      .bottomButtons .ant-space {
        justify-content: center;
      }
    }

    &.forgot_pass {
      max-width: 488px;
      padding: 32px;
      background: #fff;
      margin: 16px 0px;

      .bottomButtons .ant-btn {
        min-width: 100px;
      }

      p {
        margin-top: 16px;
      }

      .pagename {
        color: #343434;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 28px;
        position: relative;
      }

      .bottomButtons .ant-space {
        justify-content: center;
      }
    }

    p {
      margin: 0;
      padding: 0;
      margin-bottom: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #5c5c5c;
    }

    .login_inr_wrp {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      .formdiv {
        padding: 16px;
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #fff;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);

        > img {
          max-width: 50px;
        }

        .Lt {
          position: absolute;
          left: 0;
          top: 0;
        }

        .Bl {
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .inside {
          width: 100%;
          max-width: 400px;
        }

        .ant-form-item {
          margin-bottom: 24px;
          text-align: left;
        }

        .checkwrap {
          margin-bottom: 20px;

          .ant-checkbox-wrapper {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #343434;
            opacity: 0.8;
            display: inline-flex;
            align-items: center;

            .ant-checkbox {
              margin-right: 10px;
            }

            .ant-checkbox-input {
              margin: 0;
              padding: 0;
              width: 18px;
              height: 18px;
            }
          }
        }

        .pagename {
          font-style: normal;
          font-weight: 500;
          font-size: 31px;
          line-height: 20px;
          text-align: center;
          color: #343434;
          margin-bottom: 32px;
        }
      }

      .loginimg_div {
        background: #022f46;
        max-width: 400px;
        padding: 32px;
        position: relative;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);

        > img {
          max-width: 50px;
        }

        a {
          img {
            max-width: 230px;
          }
        }

        .Rt {
          position: absolute;
          right: 0;
          top: 0;
        }

        .Br {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        h3 {
          text-align: left;
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 20px;
          color: #ffffff;
          margin: 0;
          margin-bottom: 16px;
          margin-top: 32px;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
          text-align: left;
        }

        .loginimg {
          margin-top: 32px;

          img {
            max-width: 250px;
          }
        }
      }
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border: 0;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      justify-content: center;
      margin-left: -8px;
      margin-right: -8px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
      max-width: 446px;
    }

    .ant-tabs-tab {
      margin: 0px 8px;
      background: #f5f5f5;
      border-bottom: 2px solid #f5f5f5;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      color: #000;
      padding: 9px 15px;
      width: 50%;

      &.ant-tabs-tab-active {
        border-bottom: 2px solid #000;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000;
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
      color: #000;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-btn.loginbtn {
      width: 100%;
      margin: 0px;
    }

    .btnparent {
      text-align: center;
      padding-top: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
      opacity: 0.8;
    }

    .button-link {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #022f46;
      text-decoration: none;
      opacity: 1;
      background: none;
      border: 0;
      outline: 0;
      padding: 0px 8px;

      &:last-child {
        border: 0;
      }
    }

    .iconswrap {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 42px;

      .ant-btn {
        width: 100%;
      }

      .icondiv {
        margin-bottom: 24px;
      }
    }
  }

  .emailverifylogos {
    max-width: 488px;
    padding: 16px;
    margin: 16px auto;

    img {
      max-width: 100%;
      margin-bottom: 16px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      opacity: 0.7;
    }
  }

  .emailverify {
    max-width: 488px;
    padding: 32px;
    background: #fff;
    margin: 16px auto;

    .bottomButtons .ant-btn {
      min-width: 100px;
    }

    p {
      margin-top: 16px;
    }

    .pagename {
      color: #343434;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 20px;
      position: relative;
    }

    .imageverify {
      padding: 32px 0px;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #343434;
      text-align: left;
      margin-bottom: 16px;
      display: block;
    }

    .bottomButtons {
      padding-top: 32px;

      .ant-btn {
        width: 100%;
      }
    }

    .resendotp {
      text-align: center;
      flex-direction: column;
      padding-top: 16px;

      span {
        display: none;
      }

      button {
        border: 0;
        background: none;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #022f46;
      }
    }

    .otp-input {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        border: 0;
        border-bottom: 2px solid #aeaeae;
        width: 100% !important;
        height: 80px !important;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 20px;
        color: #aeaeae;
        margin: 0px 10px !important;
        max-width: 80px !important;

        &:focus,
        &:focus-visible {
          outline: 0;
          border: 0;
          border-bottom: 2px solid #aeaeae;
          outline-offset: 0px;
          outline: -webkit-focus-ring-color auto 0px;
        }
      }
    }

    .otptext {
      text-align: left;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        color: #343434;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #343434;
        opacity: 0.7;
        margin: 0;
        margin-bottom: 5px;
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #343434;
        margin-bottom: 16px;
        display: block;
      }
    }
  }

  .searchcompany {
    display: flex;
    max-width: 530px;
    background: #fff;
    margin: 16px 0px;
    padding: 32px;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 14px;
      padding: 10px 15px;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      cursor: auto;
      min-height: 35px;
      padding: 6px 4px;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
      text-align: left;
      margin-bottom: 5px;
      display: block;
    }

    .icondiv {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      margin-bottom: 32px;

      img {
        max-width: 100%;
      }
    }

    .pagename {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #343434;
      position: relative;
      max-width: 530px;
      margin: 0 auto;
      margin-bottom: 32px;
    }

    .srchwrp {
      flex: auto;

      width: 100%;

      h4 {
        margin-bottom: 16px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.6);
      }

      .ordivider {
        position: relative;
        font-size: 17px;
        margin: 16px 0px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);

        span {
          padding: 5px 10px;
          display: inline-block;
          background-color: #fff;
          position: relative;
          z-index: 9;
        }

        &::before {
          content: "";
          height: 1px;
          width: 100%;
          background: #e3e3e3;
          position: absolute;
          left: 0;
          top: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1367px) {
  .authPage {
    background-size: 20%;
  }
}

@media only screen and (max-width: 991px) {
  .authPage .login_wraper .login_inr_wrp .loginimg_div {
    max-width: 400px;
  }

  .authPage .searchcompany .srchwrp {
    padding: 32px;
  }

  .authPage .searchcompany .icondiv {
    width: 145px;
  }
}

@media only screen and (max-width: 767px) {
  .authPage {
    background-size: 30%;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div .loginimg {
    display: none;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div h3 {
    display: none;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div p {
    display: none;
  }

  .authPage .login_wraper .login_inr_wrp {
    flex-wrap: wrap;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div {
    max-width: 100%;
    width: 100%;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div > img {
    display: none;
  }

  .authPage .login_wraper .login_inr_wrp .formdiv > img {
    display: none;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div {
    padding: 16px;
  }

  .authPage .login_wraper .login_inr_wrp .loginimg_div img {
    max-width: 150px;
  }

  .authPage .reg_wraper.companydetail .pluscol {
    justify-content: flex-end;
    padding-bottom: 16px;
  }

  .authPage .logorow.headerlogin {
    flex-wrap: wrap;
  }

  .authPage .logorow.headerlogin .loginhead {
    flex: auto;
    justify-content: flex-end;
    margin-top: 16px;
    width: 100%;
  }

  .authPage .searchcompany .srchwrp {
    padding: 16px;
  }

  .authPage .searchcompany .icondiv {
    width: 50px;

    img {
      max-width: 30px;
    }
  }

  .authPage .login_wraper.passwordsuccess,
  .authPage .login_wraper.change_pass,
  .authPage .login_wraper.forgot_pass,
  .authPage .reg_wraper.companydetail,
  .authPage .reg_wraper,
  .authPage .reg_wraper,
  .authPage .emailverify {
    padding: 16px;
  }

  .authPage .login_wraper.passwordsuccess .pagename,
  .authPage .login_wraper.change_pass .pagename,
  .authPage .login_wraper.forgot_pass .pagename,
  .authPage .reg_wraper .pagename,
  .authPage .emailverify .pagename,
  .authPage .reg_wraper .pagename,
  .authPage .login_wraper .login_inr_wrp .formdiv .pagename {
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 481px) {
  .authPage {
    background-size: 36%;
  }
}

.ant-input-affix-wrapper-status-warning:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper-status-warning:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus {
  border-color: #022f46 !important;
  box-shadow: 0 0 0 2px rgba(181, 181, 181, 0.2) !important;
}

.rc-virtual-list-holder-inner .ant-select-item-option-content {
  // justify-content: space-between;
  display: inline-block;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #eee !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #022f46 !important;
}

.duplicationMsg {
  margin-top: -16px;
  display: inline-block;
  float: right;
}

.duplicationMsgLabel {
  display: "flex";
  float: "right";
}

.required-star {
  color: red;
}

.modal-wrapper {
  pointer-events: none;
}

.modal-wrapper .ant-modal {
  pointer-events: auto;
}

.ant-tooltip-inner {
  color: #000000 !important;
  background-color: #d9e0e2 !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #d9e0e2 !important;
}
